import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import ChatBox from '../components/ChatBox'
import Upgrade from '../components/Upgrade'

const UpgradePage = () => {
  return (
    <>
         <Header />
         <Sidebar />
         <Upgrade />
         <ChatBox />
    </>
  )
}

export default UpgradePage
