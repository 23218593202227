import React from "react";

const Video = () => {
  return (
    <>
      {/* main contents */}
      <main
        id="site__main"
        className="2xl:ml-[--w-side]  xl:ml-[--w-side-sm] p-2.5 h-[calc(100vh-var(--m-top))] mt-[--m-top]"
      >
        <div className="2xl:max-w-[1220px] max-w-[1065px] mx-auto">
          <div className="page-heading">
            <h1 className="page-title"> Videos </h1>
            <nav className="nav__underline">
              <ul
                uk-tab=""
                className="group"
                uk-switcher="connect: #ttabs ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
              >
                <li>
                  {" "}
                  <a href="#"> Suggestions</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#"> Popular </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#"> My videos </a>{" "}
                </li>
              </ul>
            </nav>
          </div>
          <div className="mt-10">
            {/* page feautred */}
            <div className="relative" tabIndex={-1} uk-slider="finite:true">
              <div className="uk-slider-container pb-1">
                <ul className="uk-slider-items grid-small">
                  <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="video-watch.html">
                        <div className="card-media sm:aspect-[2/1.1] h-28">
                          <img src="/static/assets/images/video/img-1.png" alt="" />
                          <div className="card-overly" />
                          <img
                            src="/static/assets/images/icon-play.svg"
                            className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                            alt=""
                          />
                          <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                            {" "}
                            12:21
                          </span>
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="video-watch.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Amazing house construction from foundation to last
                            detail
                          </h4>
                        </a>
                        <p className="card-text mt-1.5">
                          {" "}
                          <a href="timeline.html"> 111 Jesse Steeve </a>{" "}
                        </p>
                        <div className="card-list-info text-xs mt-1 flex-wrap">
                          <div> 27 weeks ago</div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="video-watch.html">
                        <div className="card-media sm:aspect-[2/1.1] h-28">
                          <img src="/static/assets/images/video/img-2.png" alt="" />
                          <div className="card-overly" />
                          <img
                            src="/static/assets/images/icon-play.svg"
                            className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                            alt=""
                          />
                          <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                            {" "}
                            12:21
                          </span>
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="video-watch.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Great drone jet testing range flat model show with
                            advance
                          </h4>
                        </a>
                        <p className="card-text mt-1.5">
                          {" "}
                          <a href="timeline.html"> John Michael </a>{" "}
                        </p>
                        <div className="card-list-info text-xs mt-1 flex-wrap">
                          <div> 27 weeks ago</div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="video-watch.html">
                        <div className="card-media sm:aspect-[2/1.1] h-28">
                          <img src="/static/assets/images/video/img-3.png" alt="" />
                          <div className="card-overly" />
                          <img
                            src="/static/assets/images/icon-play.svg"
                            className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                            alt=""
                          />
                          <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                            {" "}
                            12:21
                          </span>
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="video-watch.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Exploring latest an abandoned water park in china
                            city
                          </h4>
                        </a>
                        <p className="card-text mt-1.5">
                          {" "}
                          <a href="timeline.html"> Monroe Parker </a>{" "}
                        </p>
                        <div className="card-list-info text-xs mt-1 flex-wrap">
                          <div> 27 weeks ago</div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="video-watch.html">
                        <div className="card-media sm:aspect-[2/1.1] h-28">
                          <img src="/static/assets/images/video/img-4.png" alt="" />
                          <div className="card-overly" />
                          <img
                            src="/static/assets/images/icon-play.svg"
                            className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                            alt=""
                          />
                          <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                            {" "}
                            12:21
                          </span>
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="video-watch.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            cheese cake eggless without oven hida mari cooking{" "}
                          </h4>
                        </a>
                        <p className="card-text mt-1.5">
                          {" "}
                          <a href="timeline.html"> Martin Gray </a>{" "}
                        </p>
                        <div className="card-list-info text-xs mt-1 flex-wrap">
                          <div> 27 weeks ago</div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="video-watch.html">
                        <div className="card-media sm:aspect-[2/1.1] h-28">
                          <img src="/static/assets/images/video/img-5.png" alt="" />
                          <div className="card-overly" />
                          <img
                            src="/static/assets/images/icon-play.svg"
                            className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                            alt=""
                          />
                          <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                            {" "}
                            12:21
                          </span>
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="video-watch.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Amazing little girl has very talent voice vocal
                            coach
                          </h4>
                        </a>
                        <p className="card-text mt-1.5">
                          {" "}
                          <a href="timeline.html"> James Lewis </a>{" "}
                        </p>
                        <div className="card-list-info text-xs mt-1 flex-wrap">
                          <div> 27 weeks ago</div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* slide nav icons */}
              <a
                className="nav-prev !top-20"
                href="#"
                uk-slider-item="previous"
              >
                {" "}
                <ion-icon name="chevron-back" className="text-2xl" />{" "}
              </a>
              <a className="nav-next !top-20" href="#" uk-slider-item="next">
                {" "}
                <ion-icon name="chevron-forward" className="text-2xl" />
              </a>
            </div>
            {/* video grid with out slider  is hidden by default */}
            <div className="grid 2xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-2.5 mt-10 hidden">
              <div className="card">
                <a href="video-watch.html">
                  <div className="card-media sm:aspect-[2/1.1] h-28">
                    <img src="/static/assets/images/video/img-1.png" alt="" />
                    <div className="card-overly" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-body">
                  <a href="video-watch.html">
                    <h4 className="card-title text-sm line-clamp-2">
                      {" "}
                      Amazing house construction from foundation to last detail
                    </h4>
                  </a>
                  <p className="card-text mt-1.5">
                    {" "}
                    <a href="timeline.html"> Jesse Steeve </a>{" "}
                  </p>
                  <div className="card-list-info text-xs mt-1 flex-wrap">
                    <div> 27 weeks ago</div>
                    <div className="md:block hidden">·</div>
                    <div> 156.9K views</div>
                  </div>
                </div>
              </div>
              <div className="card">
                <a href="video-watch.html">
                  <div className="card-media sm:aspect-[2/1.1] h-28">
                    <img src="/static/assets/images/video/img-2.png" alt="" />
                    <div className="card-overly" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-body">
                  <a href="video-watch.html">
                    <h4 className="card-title text-sm line-clamp-2">
                      {" "}
                      Great drone jet testing range flat model show with advance
                    </h4>
                  </a>
                  <p className="card-text mt-1.5">
                    {" "}
                    <a href="timeline.html"> John Michael </a>{" "}
                  </p>
                  <div className="card-list-info text-xs mt-1 flex-wrap">
                    <div> 27 weeks ago</div>
                    <div className="md:block hidden">·</div>
                    <div> 156.9K views</div>
                  </div>
                </div>
              </div>
              <div className="card">
                <a href="video-watch.html">
                  <div className="card-media sm:aspect-[2/1.1] h-28">
                    <img src="/static/assets/images/video/img-3.png" alt="" />
                    <div className="card-overly" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-body">
                  <a href="video-watch.html">
                    <h4 className="card-title text-sm line-clamp-2">
                      {" "}
                      Exploring latest an abandoned water park in china city
                    </h4>
                  </a>
                  <p className="card-text mt-1.5">
                    {" "}
                    <a href="timeline.html"> Monroe Parker </a>{" "}
                  </p>
                  <div className="card-list-info text-xs mt-1 flex-wrap">
                    <div> 27 weeks ago</div>
                    <div className="md:block hidden">·</div>
                    <div> 156.9K views</div>
                  </div>
                </div>
              </div>
              <div className="card">
                <a href="video-watch.html">
                  <div className="card-media sm:aspect-[2/1.1] h-28">
                    <img src="/static/assets/images/video/img-5.png" alt="" />
                    <div className="card-overly" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-body">
                  <a href="video-watch.html">
                    <h4 className="card-title text-sm line-clamp-2">
                      {" "}
                      Amazing little girl has very talent voice vocal coach
                    </h4>
                  </a>
                  <p className="card-text mt-1.5">
                    {" "}
                    <a href="timeline.html"> James Lewis </a>{" "}
                  </p>
                  <div className="card-list-info text-xs mt-1 flex-wrap">
                    <div> 27 weeks ago</div>
                    <div className="md:block hidden">·</div>
                    <div> 156.9K views</div>
                  </div>
                </div>
              </div>
            </div>
            {/* second title */}
            <div className="flex items-center justify-between text-black dark:text-white py-3 mt-10">
              <h3 className="text-xl font-semibold"> Your videos </h3>
              <a href="#" className="text-sm text-blue-500">
                See all
              </a>
            </div>
            {/* card list  */}
            <div className="box p-6 mt-6">
              <div className="card-list">
                <a href="video-watch.html">
                  <div className="card-list-media md:w-[320px] md:h-[180px] sm:aspect-[3/1.2] aspect-[3/1.5]">
                    <img src="/static/assets/images/video/img-2.png" alt="" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-list-body relative">
                  <a href="video-watch.html">
                    {" "}
                    <h3 className="card-list-title lg:mt-2 lg:line-clamp-1">
                      {" "}
                      Great drone jet testing range flat model show with advance
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="timeline.html">
                    {" "}
                    <div className="card-list-link mt-5">
                      {" "}
                      John Michael{" "}
                    </div>{" "}
                  </a>
                  <div className="flex items-center justify-between">
                    <div className="card-list-info">
                      <div> 27 weeks ago</div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                    <button
                      type="button"
                      className=" bg-second ery px-3.5 py-1.5 rounded-lg border2 md:inline-block hidden text-sm"
                    >
                      {" "}
                      Add favorites{" "}
                    </button>
                  </div>
                  {/* dropdown menu */}
                  <div className=" absolute top-0 right-0 -m-1">
                    <button
                      type="button"
                      className="hover:bg-secondery w-10 h-10 rounded-full grid place-items-center"
                    >
                      {" "}
                      <ion-icon
                        className="text-2xl"
                        name="ellipsis-horizontal"
                      />{" "}
                    </button>
                    <div
                      className="w-[245px]"
                      uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click"
                    >
                      <nav>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="bookmark-outline"
                          />{" "}
                          Add to favorites{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="albums-outline"
                          />{" "}
                          add to collections{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="flag-outline"
                          />{" "}
                          Report{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="share-outline"
                          />{" "}
                          Share{" "}
                        </a>
                        <hr />
                        <a
                          href="#"
                          className="text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                        >
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="trash-outline"
                          />{" "}
                          Delete
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="card-list-divider" />
              <div className="card-list">
                <a href="video-watch.html">
                  <div className="card-list-media md:w-[320px] md:h-[180px] sm:aspect-[3/1.2] aspect-[3/1.5]">
                    <img src="/static/assets/images/video/img-3.png" alt="" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-list-body relative">
                  <a href="video-watch.html">
                    {" "}
                    <h3 className="card-list-title lg:mt-2 lg:line-clamp-1">
                      {" "}
                      Exploring latest an abandoned water park in china city
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="timeline.html">
                    {" "}
                    <div className="card-list-link mt-5">
                      {" "}
                      Monroe Parker{" "}
                    </div>{" "}
                  </a>
                  <div className="flex items-center justify-between">
                    <div className="card-list-info">
                      <div> 27 weeks ago</div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                    <button
                      type="button"
                      className=" bg-second ery px-3.5 py-1.5 rounded-lg border2 md:inline-block hidden text-sm"
                    >
                      {" "}
                      Add favorites{" "}
                    </button>
                  </div>
                  {/* dropdown menu */}
                  <div className=" absolute top-0 right-0 -m-1">
                    <button
                      type="button"
                      className="hover:bg-secondery w-10 h-10 rounded-full grid place-items-center"
                    >
                      {" "}
                      <ion-icon
                        className="text-2xl"
                        name="ellipsis-horizontal"
                      />{" "}
                    </button>
                    <div
                      className="w-[245px]"
                      uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click"
                    >
                      <nav>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="bookmark-outline"
                          />{" "}
                          Add to favorites{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="albums-outline"
                          />{" "}
                          add to collections{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="flag-outline"
                          />{" "}
                          Report{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="share-outline"
                          />{" "}
                          Share{" "}
                        </a>
                        <hr />
                        <a
                          href="#"
                          className="text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                        >
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="trash-outline"
                          />{" "}
                          Delete
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="card-list-divider" />
              <div className="card-list">
                <a href="video-watch.html">
                  <div className="card-list-media md:w-[320px] md:h-[180px] sm:aspect-[3/1.2] aspect-[3/1.5]">
                    <img src="/static/assets/images/video/img-4.png" alt="" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-list-body relative">
                  <a href="video-watch.html">
                    {" "}
                    <h3 className="card-list-title lg:mt-2 lg:line-clamp-1">
                      {" "}
                      cheese cake eggless without oven hida mari cooking{" "}
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="timeline.html">
                    {" "}
                    <div className="card-list-link mt-5">
                      {" "}
                      Martin Gray{" "}
                    </div>{" "}
                  </a>
                  <div className="flex items-center justify-between">
                    <div className="card-list-info">
                      <div> 27 weeks ago</div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                    <button
                      type="button"
                      className=" bg-second ery px-3.5 py-1.5 rounded-lg border2 md:inline-block hidden text-sm"
                    >
                      {" "}
                      Add favorites{" "}
                    </button>
                  </div>
                  {/* dropdown menu */}
                  <div className=" absolute top-0 right-0 -m-1">
                    <button
                      type="button"
                      className="hover:bg-secondery w-10 h-10 rounded-full grid place-items-center"
                    >
                      {" "}
                      <ion-icon
                        className="text-2xl"
                        name="ellipsis-horizontal"
                      />{" "}
                    </button>
                    <div
                      className="w-[245px]"
                      uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click"
                    >
                      <nav>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="bookmark-outline"
                          />{" "}
                          Add to favorites{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="albums-outline"
                          />{" "}
                          add to collections{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="flag-outline"
                          />{" "}
                          Report{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="share-outline"
                          />{" "}
                          Share{" "}
                        </a>
                        <hr />
                        <a
                          href="#"
                          className="text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                        >
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="trash-outline"
                          />{" "}
                          Delete
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="card-list-divider" />
              <div className="card-list">
                <a href="video-watch.html">
                  <div className="card-list-media md:w-[320px] md:h-[180px] sm:aspect-[3/1.2] aspect-[3/1.5]">
                    <img src="/static/assets/images/video/img-5.png" alt="" />
                    <img
                      src="/static/assets/images/icon-play.svg"
                      className="!w-12 !h-12 absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    />
                    <span className="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs z-10">
                      {" "}
                      12:21
                    </span>
                  </div>
                </a>
                <div className="card-list-body relative">
                  <a href="video-watch.html">
                    {" "}
                    <h3 className="card-list-title lg:mt-2 lg:line-clamp-1">
                      {" "}
                      Amazing little girl has very talent voice vocal coach
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="timeline.html">
                    {" "}
                    <div className="card-list-link mt-5">
                      {" "}
                      James Lewis{" "}
                    </div>{" "}
                  </a>
                  <div className="flex items-center justify-between">
                    <div className="card-list-info">
                      <div> 27 weeks ago</div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                    <button
                      type="button"
                      className=" bg-second ery px-3.5 py-1.5 rounded-lg border2 md:inline-block hidden text-sm"
                    >
                      {" "}
                      Add favorites{" "}
                    </button>
                  </div>
                  {/* dropdown menu */}
                  <div className=" absolute top-0 right-0 -m-1">
                    <button
                      type="button"
                      className="hover:bg-secondery w-10 h-10 rounded-full grid place-items-center"
                    >
                      {" "}
                      <ion-icon
                        className="text-2xl"
                        name="ellipsis-horizontal"
                      />{" "}
                    </button>
                    <div
                      className="w-[245px]"
                      uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click"
                    >
                      <nav>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="bookmark-outline"
                          />{" "}
                          Add to favorites{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="albums-outline"
                          />{" "}
                          add to collections{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="flag-outline"
                          />{" "}
                          Report{" "}
                        </a>
                        <a href="#">
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="share-outline"
                          />{" "}
                          Share{" "}
                        </a>
                        <hr />
                        <a
                          href="#"
                          className="text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                        >
                          {" "}
                          <ion-icon
                            className="text-xl"
                            name="trash-outline"
                          />{" "}
                          Delete
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* load more */}
            <div className="flex justify-center my-6">
              <button
                type="button"
                className="bg-white py-2 px-5 rounded-full shadow-md font-semibold text-sm dark:bg-dark2"
              >
                Load more...
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Video;
