import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Feed from "../components/Feed";
import ChatBox from "../components/ChatBox";
import CreateStatus from "../components/CreateStatus";
import CreateStory from "../components/CreateStory";
import PreviewModal from "../components/PreviewModal";

const Home = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Feed />
      <ChatBox />
      <CreateStatus />
      <CreateStory />
      <PreviewModal />
    </>
  );
};

export default Home;
