import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Timeline from '../components/Timeline'
import ChatBox from '../components/ChatBox'
import CreateStatus from '../components/CreateStatus'

const TimelinePage = () => {
  return (
    <>
        <Header />
        <Sidebar />
        <Timeline />
        <ChatBox />
        <CreateStatus />
    </>
  )
}

export default TimelinePage
