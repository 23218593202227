import React from "react";

const Blog = () => {
  return (
    <>
      {/* main contents */}
      <main
        id="site__main"
        className="2xl:ml-[--w-side]  xl:ml-[--w-side-sm] p-2.5 h-[calc(100vh-var(--m-top))] mt-[--m-top]"
      >
        <div
          className="flex max-lg:flex-col 2xl:gap-12 gap-10 2xl:max-w-[1220px] max-w-[1065px] mx-auto"
          id="js-oversized"
        >
          <div className="flex-1">
            <div className="page-heading">
              <h1 className="page-title"> Articles </h1>
              <nav className="nav__underline">
                <ul
                  uk-tab=""
                  className="group"
                  uk-switcher="connect: #ttabs ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
                >
                  <li>
                    {" "}
                    <a href="#"> Suggestions </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Popular </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> My article </a>{" "}
                  </li>
                </ul>
              </nav>
            </div>
            {/*  feature blog slider */}
            <div tabIndex={-1} uk-slider="finite:true">
              <div className="uk-slider-container pb-1">
                <ul className="uk-slider-items grid-small">
                  <li className="sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="blog-read.html">
                        <div className="card-media h-32">
                          <img src="/static/assets/images/blog/img-1.jpg" alt="" />
                          <div className="card-overly" />
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="blog-read.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Top amazing web demos and experiments in 2024 should
                            know about{" "}
                          </h4>
                        </a>
                        <p className="card-text text-xs mt-1.5">
                          {" "}
                          <a href="#"> Jesse Steeve </a>{" "}
                        </p>
                        <div className="card-list-info mt-1 text-xs">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="blog-read.html">
                        <div className="card-media h-32">
                          <img src="/static/assets/images/blog/img-2.jpg" alt="" />
                          <div className="card-overly" />
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="blog-read.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Awesome web dev tools and resources for 2024 in 30
                            minutes
                          </h4>
                        </a>
                        <p className="card-text text-xs mt-1.5">
                          {" "}
                          <a href="#"> John Michael</a>{" "}
                        </p>
                        <div className="card-list-info mt-1 text-xs">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="blog-read.html">
                        <div className="card-media h-32">
                          <img src="/static/assets/images/blog/img-3.jpg" alt="" />
                          <div className="card-overly" />
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="blog-read.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            {" "}
                            Interesting JavaScript and CSS libraries should Know
                            About{" "}
                          </h4>
                        </a>
                        <p className="card-text text-xs mt-1.5">
                          {" "}
                          <a href="#"> Monroe Parker </a>{" "}
                        </p>
                        <div className="card-list-info mt-1 text-xs">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="sm:w-1/3 w-1/2">
                    <div className="card">
                      <a href="blog-read.html">
                        <div className="card-media h-32">
                          <img src="/static/assets/images/blog/img-4.jpg" alt="" />
                          <div className="card-overly" />
                        </div>
                      </a>
                      <div className="card-body">
                        <a href="blog-read.html">
                          <h4 className="card-title text-sm line-clamp-2">
                            Interesting javaScript and CSS libraries you should
                            be learn
                          </h4>
                        </a>
                        <p className="card-text text-xs mt-1.5">
                          {" "}
                          <a href="#"> Martin Gray </a>{" "}
                        </p>
                        <div className="card-list-info mt-1 text-xs">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* slide nav icons */}
              <a
                className="nav-prev !top-24"
                href="#"
                uk-slider-item="previous"
              >
                {" "}
                <ion-icon name="chevron-back" className="text-2xl" />{" "}
              </a>
              <a className="nav-next !top-24" href="#" uk-slider-item="next">
                {" "}
                <ion-icon name="chevron-forward" className="text-2xl" />
              </a>
            </div>
            <div className="card mt-8">
              <div className="card-media md:h-80 h-52">
                <img src="/static/assets/images/group/group-cover-1.jpg" alt="" />
                <div className="card-overly" />
              </div>
              <div className="card-body p-5 w-full z-10 absolute bg-gradient-to-t bottom-0 from-black/60">
                <p className="card-text text-white/80">
                  {" "}
                  <a href="#"> Denise Marie </a>{" "}
                </p>
                <h4 className="card-title text-xl mt-1.5 !text-white">
                  {" "}
                  Exploring an Abandoned Water Park in China{" "}
                </h4>
                <div className="card-list-info font-medium mt-1 text-xs mt-1.5 gap-2.5 text-white">
                  <div className="flex items-center gap-1.5 text-sm">
                    {" "}
                    27{" "}
                    <ion-icon
                      name="thumbs-up-outline"
                      className="text-base"
                    />{" "}
                  </div>
                  <div className="flex items-center gap-1.5 text-sm">
                    {" "}
                    156.9K{" "}
                    <ion-icon
                      name="chatbox-outline"
                      className="text-base"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* recomended writters*/}
            <div className="bg-secondery p-6 rounded-md mt-8 shadow dark:bg-dark2">
              <div className="flex items-center justify-between text-black dark:text-white">
                <h3 className="text-lg font-semibold">
                  {" "}
                  Recommended Writters for you{" "}
                </h3>
                <a
                  href="#"
                  className="text-sm text-blue-500 flex items-center gap-2"
                >
                  See all <ion-icon name="arrow-forward-outline" />{" "}
                </a>
              </div>
              <div className="grid sm:grid-cols-2 gap-3 mt-4">
                <div className="side-list-item p-4 box dark:bg-white/5">
                  <a href="timeline.html">
                    <img
                      src="/static/assets/images/avatars/avatar-5.jpg"
                      alt=""
                      className="rounded-full w-10 h-10"
                    />
                  </a>
                  <div className="flex-1">
                    <a href="timeline.html">
                      <h4 className="side-list-title"> James Lewis</h4>
                    </a>
                    <div className="side-list-info"> 42 articles </div>
                  </div>
                  <button
                    type="button"
                    className="button-icon bg-primary-soft text-primary dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="side-list-item p-4 box dark:bg-white/5">
                  <a href="timeline.html">
                    <img
                      src="/static/assets/images/avatars/avatar-4.jpg"
                      alt=""
                      className="rounded-full w-10 h-10"
                    />
                  </a>
                  <div className="flex-1">
                    <a href="timeline.html">
                      <h4 className="side-list-title"> Martin Gray</h4>
                    </a>
                    <div className="side-list-info"> 25 articles </div>
                  </div>
                  <button
                    type="button"
                    className="button-icon bg-primary-soft text-primary dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="side-list-item p-4 box dark:bg-white/5">
                  <a href="timeline.html">
                    <img
                      src="/static/assets/images/avatars/avatar-3.jpg"
                      alt=""
                      className="bg-gray-200 rounded-full w-10 h-10"
                    />
                  </a>
                  <div className="flex-1">
                    <a href="timeline.html">
                      <h4 className="side-list-title"> Monroe Parker</h4>
                    </a>
                    <div className="side-list-info"> 15 articles </div>
                  </div>
                  <button
                    type="button"
                    className="button-icon bg-primary-soft text-primary dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="side-list-item p-4 box dark:bg-white/5">
                  <a href="timeline.html">
                    <img
                      src="/static/assets/images/avatars/avatar-7.jpg"
                      alt=""
                      className="bg-gray-200 rounded-full w-10 h-10"
                    />
                  </a>
                  <div className="flex-1">
                    <a href="timeline.html">
                      <h4 className="side-list-title"> Jesse Steeve</h4>
                    </a>
                    <div className="side-list-info"> 36 articles </div>
                  </div>
                  <button
                    type="button"
                    className="button-icon bg-primary-soft text-primary dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* title */}
            <div className="flex items-center justify-between text-black dark:text-white py-3 mt-8">
              <h3 className="text-xl font-semibold"> Articles for you </h3>
              <a href="#" className="text-sm text-blue-500">
                See all
              </a>
            </div>
            {/* articale list layout */}
            <div className="box p-5 mt-4">
              <div className="card-list">
                <a href="blog-read.html" className="lg:order-1">
                  <div className="card-list-media h-32">
                    <img src="/static/assets/images/blog/img-2.jpg" alt="" />
                  </div>
                </a>
                <div className="card-list-body">
                  <a href="blog-read.html">
                    {" "}
                    <h3 className="card-list-title">
                      {" "}
                      Top amazing web demos and experiments in 2024 should know
                      about{" "}
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="#">
                    {" "}
                    <div className="card-list-link"> Jesse Steeve </div>{" "}
                  </a>
                  <div className="card-list-info">
                    <div className="flex items-center gap-2">
                      <ion-icon name="heart-outline" className="text-lg" />
                      45
                    </div>
                    <div className="md:block hidden">·</div>
                    <div className="flex items-center gap-2">
                      <ion-icon
                        name="chatbubble-ellipses-outline"
                        className="text-lg"
                      />
                      156.9K
                    </div>
                  </div>
                </div>
              </div>
              <hr className="card-list-divider" />
              <div className="card-list">
                <a href="blog-read.html" className="lg:order-1">
                  <div className="card-list-media h-32">
                    <img src="/static/assets/images/blog/img-3.jpg" alt="" />
                  </div>
                </a>
                <div className="card-list-body">
                  <a href="blog-read.html">
                    {" "}
                    <h3 className="card-list-title">
                      {" "}
                      Interesting JavaScript and CSS libraries should Know About{" "}
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="#">
                    {" "}
                    <div className="card-list-link"> Monroe Parker </div>{" "}
                  </a>
                  <div className="card-list-info">
                    <div className="flex items-center gap-2">
                      <ion-icon name="heart-outline" className="text-lg" />
                      45
                    </div>
                    <div className="md:block hidden">·</div>
                    <div className="flex items-center gap-2">
                      <ion-icon
                        name="chatbubble-ellipses-outline"
                        className="text-lg"
                      />
                      156.9K
                    </div>
                  </div>
                </div>
              </div>
              <hr className="card-list-divider" />
              <div className="card-list">
                <a href="blog-read.html" className="lg:order-1">
                  <div className="card-list-media h-32">
                    <img src="/static/assets/images/blog/img-4.jpg" alt="" />
                  </div>
                </a>
                <div className="card-list-body">
                  <a href="blog-read.html">
                    {" "}
                    <h3 className="card-list-title">
                      Interesting javaScript and CSS libraries you should be
                      learn
                    </h3>{" "}
                  </a>
                  <p className="card-list-text">
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam,
                  </p>
                  <a href="#">
                    {" "}
                    <div className="card-list-link"> Martin Gray </div>{" "}
                  </a>
                  <div className="card-list-info">
                    <div className="flex items-center gap-2">
                      <ion-icon name="heart-outline" className="text-lg" />
                      45
                    </div>
                    <div className="md:block hidden">·</div>
                    <div className="flex items-center gap-2">
                      <ion-icon
                        name="chatbubble-ellipses-outline"
                        className="text-lg"
                      />
                      156.9K
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center my-6">
              <button
                type="button"
                className="bg-white py-2 px-5 rounded-full shadow-md font-semibold text-sm dark:bg-dark2"
              >
                Load more...
              </button>
            </div>
          </div>
          {/* sidebar */}
          <div className="2xl:w-[380px] lg:w-[330px] w-full">
            <div
              className="lg:space-y-6 space-y-4 lg:pb-8 max-lg:grid sm:grid-cols-2 max-lg:gap-6"
              uk-sticky="media: 1024; end: #js-oversized; offset: 80"
            >
              <div
                className="relative uk-visible-toggle"
                tabIndex={-1}
                uk-slideshow="finite: true ; min-height: 300; max-height: 500"
              >
                <ul className="uk-slideshow-items">
                  <li className="w-full overflow-hidden rounded-xl">
                    <img
                      src="/static/assets/images/blog/img-3.jpg"
                      alt=""
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 w-full uk-transition-slide-bottom-small">
                      <div className="bg-black/10 p-4 m-2 rounded-md backdrop-blur-lg mb-8">
                        <a href="timeline.html">
                          <h4 className="text-sm font-medium !text-white">
                            {" "}
                            Interesting JavaScript and CSS libraries should Know
                            About
                          </h4>
                        </a>
                        <div className="text-xs text-white/80 mt-2 flex items-center gap-2">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="w-full overflow-hidden rounded-md">
                    <img
                      src="/static/assets/images/blog/img-2.jpg"
                      alt=""
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 w-full uk-transition-slide-bottom-small">
                      <div className="bg-black/10 p-4 m-2 rounded-md backdrop-blur-lg mb-8">
                        <a href="timeline.html">
                          <h4 className="text-sm font-medium !text-white">
                            {" "}
                            Awesome web dev tools and resources for 2024 in 30
                            minutes{" "}
                          </h4>
                        </a>
                        <div className="text-xs text-white/80 mt-2 flex items-center gap-2">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="w-full overflow-hidden rounded-md">
                    <img
                      src="/static/assets/images/blog/img-4.jpg"
                      alt=""
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 w-full uk-transition-slide-bottom-small">
                      <div className="bg-black/10 p-4 m-2 rounded-md backdrop-blur-lg mb-8">
                        <a href="timeline.html">
                          <h4 className="text-sm font-medium !text-white">
                            {" "}
                            Interesting javaScript and CSS libraries you should
                            be learn
                          </h4>
                        </a>
                        <div className="text-xs text-white/80 mt-2 flex items-center gap-2">
                          <div> 10 Jun 2022 </div>
                          <div className="md:block hidden">·</div>
                          <div> 156.9K views</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* slide nav */}
                <div className="flex justify-center">
                  <ul className="inline-flex flex-wrap justify-center  absolute bottom-3 gap-1.5 uk-dotnav uk-slideshow-nav">
                    {" "}
                  </ul>
                </div>
                {/* slide nav icons */}
                <a className="nav-prev" href="#" uk-slideshow-item="previous">
                  {" "}
                  <ion-icon name="chevron-back" className="text-2xl" />{" "}
                </a>
                <a className="nav-next" href="#" uk-slideshow-item="next">
                  {" "}
                  <ion-icon name="chevron-forward" className="text-2xl" />
                </a>
              </div>
              <div className="box p-5 px-6">
                <div className="flex items-baseline justify-between text-black dark:text-white">
                  <h3 className="font-bold text-base"> Trending Articles</h3>
                  <a href="#" className="text-sm text-blue-500">
                    See all
                  </a>
                </div>
                <div className="mt-4 space-y-4">
                  <div>
                    <a href="blog-read.html">
                      <h4 className="text-sm font-normal text-black dark:text-white duration-200 hover:opacity-80">
                        Interesting javaScript and CSS libraries you should be
                        learn
                      </h4>
                    </a>
                    <div className="text-xs text-gray-400 mt-2 flex items-center gap-2">
                      <div> 10 Jun 2022 </div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                  </div>
                  <div>
                    <a href="blog-read.html">
                      <h4 className="text-sm font-normal text-black dark:text-white duration-200 hover:opacity-80">
                        {" "}
                        Interesting javaScript and CSS libraries you should be
                        learn
                      </h4>
                    </a>
                    <div className="text-xs text-gray-400 mt-2 flex items-center gap-2">
                      <div> 10 Jun 2022 </div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                  </div>
                  <div>
                    <a href="blog-read.html">
                      <h4 className="text-sm font-normal text-black dark:text-white duration-200 hover:opacity-80">
                        {" "}
                        Interesting JavaScript and CSS libraries should Know
                        About
                      </h4>
                    </a>
                    <div className="text-xs text-gray-400 mt-2 flex items-center gap-2">
                      <div> 10 Jun 2022 </div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                  </div>
                  <div>
                    <a href="blog-read.html">
                      <h4 className="text-sm font-normal text-black dark:text-white duration-200 hover:opacity-80">
                        Top amazing web demos and experiments should know about
                      </h4>
                    </a>
                    <div className="text-xs text-gray-400 mt-2 flex items-center gap-2">
                      <div> 10 Jun 2022 </div>
                      <div className="md:block hidden">·</div>
                      <div> 156.9K views</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* peaple you might know */}
              <div className="box p-5 px-6 border1 dark:bg-dark2">
                <div className="flex justify-between text-black dark:text-white">
                  <h3 className="font-bold text-base">
                    {" "}
                    Peaple You might know{" "}
                  </h3>
                  <button type="button">
                    {" "}
                    <ion-icon name="sync-outline" className="text-xl" />{" "}
                  </button>
                </div>
                <div className="space-y-4 capitalize text-xs font-normal mt-5 mb-2 text-gray-500 dark:text-white/80">
                  <div className="flex items-center gap-3">
                    <a href="timeline.html">
                      <img
                        src="/static/assets/images/avatars/avatar-7.jpg"
                        alt=""
                        className="bg-gray-200 rounded-full w-10 h-10"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline.html">
                        <h4 className="font-semibold text-sm text-black dark:text-white">
                          {" "}
                          Johnson smith
                        </h4>
                      </a>
                      <div className="mt-0.5"> Suggested For You </div>
                    </div>
                    <button
                      type="button"
                      className="text-sm rounded-full py-1.5 px-4 font-semibold bg-secondery"
                    >
                      {" "}
                      Follow{" "}
                    </button>
                  </div>
                  <div className="flex items-center gap-3">
                    <a href="timeline.html">
                      <img
                        src="/static/assets/images/avatars/avatar-5.jpg"
                        alt=""
                        className="bg-gray-200 rounded-full w-10 h-10"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline.html">
                        <h4 className="font-semibold text-sm text-black dark:text-white">
                          {" "}
                          James Lewis
                        </h4>
                      </a>
                      <div className="mt-0.5"> Followed by Johnson </div>
                    </div>
                    <button
                      type="button"
                      className="text-sm rounded-full py-1.5 px-4 font-semibold bg-secondery"
                    >
                      {" "}
                      Follow{" "}
                    </button>
                  </div>
                  <div className="flex items-center gap-3">
                    <a href="timeline.html">
                      <img
                        src="/static/assets/images/avatars/avatar-2.jpg"
                        alt=""
                        className="bg-gray-200 rounded-full w-10 h-10"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline.html">
                        <h4 className="font-semibold text-sm text-black dark:text-white">
                          {" "}
                          John Michael
                        </h4>
                      </a>
                      <div className="mt-0.5"> Followed by Monroe</div>
                    </div>
                    <button
                      type="button"
                      className="text-sm rounded-full py-1.5 px-4 font-semibold bg-secondery"
                    >
                      {" "}
                      Follow{" "}
                    </button>
                  </div>
                  <div className="flex items-center gap-3">
                    <a href="timeline.html">
                      <img
                        src="/static/assets/images/avatars/avatar-3.jpg"
                        alt=""
                        className="bg-gray-200 rounded-full w-10 h-10"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline.html">
                        <h4 className="font-semibold text-sm text-black dark:text-white">
                          {" "}
                          Monroe Parker
                        </h4>
                      </a>
                      <div className="mt-0.5"> Suggested For You </div>
                    </div>
                    <button
                      type="button"
                      className="text-sm rounded-full py-1.5 px-4 font-semibold bg-secondery"
                    >
                      {" "}
                      Follow{" "}
                    </button>
                  </div>
                  <div className="flex items-center gap-3">
                    <a href="timeline.html">
                      <img
                        src="/static/assets/images/avatars/avatar-4.jpg"
                        alt=""
                        className="bg-gray-200 rounded-full w-10 h-10"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline.html">
                        <h4 className="font-semibold text-sm text-black dark:text-white">
                          {" "}
                          Martin Gray
                        </h4>
                      </a>
                      <div className="mt-0.5"> Suggested For You </div>
                    </div>
                    <button
                      type="button"
                      className="text-sm rounded-full py-1.5 px-4 font-semibold bg-secondery"
                    >
                      {" "}
                      Follow{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Blog;
