import React from "react";

const Follow = () => {
  return (
    <>
      {/* main contents */}
      <main
        id="site__main"
        className="2xl:ml-[--w-side]  xl:ml-[--w-side-sm] p-2.5 h-[calc(100vh-var(--m-top))] mt-[--m-top]"
      >
        <div
          className="flex max-lg:flex-col 2xl:gap-12 gap-10 2xl:max-w-[1220px] max-w-[1065px] mx-auto"
          id="js-oversized"
        >
          <div className="flex-1">
            <div className="max-w-[680px] w-full mx-auto">
              <div className="page-heading">
                <h1 className="page-title"> Pages </h1>
                <nav className="nav__underline">
                  <ul
                    className="group"
                    uk-switcher="connect: #page-tabs ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
                  >
                    <li>
                      {" "}
                      <a href="#"> Suggestions</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#"> Popular </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#"> My pages </a>{" "}
                    </li>
                  </ul>
                </nav>
              </div>
              {/* page feautred */}
              <div tabIndex={-1} uk-slider="finite:true">
                <div className="uk-slider-container pb-1">
                  <ul className="uk-slider-items grid-small">
                    <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                      <div className="card uk-transition-toggle">
                        <a href="timeline-page.html">
                          <div className="card-media sm:aspect-[2/1.9] h-40">
                            <img
                              src="/static/assets/images/avatars/avatar-1.jpg"
                              alt=""
                            />
                            <div className="card-overly" />
                          </div>
                        </a>
                        <div className="card-body p-3 w-full z-10 absolute bg-gradient-to-t bottom-0 from-black/60">
                          <p className="card-text text-xs text-white/80">
                            {" "}
                            Japan{" "}
                          </p>
                          <a href="timeline-page.html">
                            <h4 className="card-title text-sm mt-0.5 !text-white">
                              {" "}
                              Jesse Steeve{" "}
                            </h4>
                          </a>
                        </div>
                        {/* close */}
                        <button
                          type="button"
                          className="uk-transition-fade absolute top-0 right-0 m-2 z-10 bg-black/20 rounded-full flex p-1"
                        >
                          <ion-icon name="close" className="text-white" />
                        </button>
                      </div>
                    </li>
                    <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                      <div className="card uk-transition-toggle">
                        <a href="timeline-page.html">
                          <div className="card-media sm:aspect-[2/1.9] h-40">
                            <img
                              src="/static/assets/images/avatars/avatar-2.jpg"
                              alt=""
                            />
                            <div className="card-overly" />
                          </div>
                        </a>
                        <div className="card-body p-3 w-full z-10 absolute bg-gradient-to-t bottom-0 from-black/60">
                          <p className="card-text text-xs text-white/80">
                            {" "}
                            Turkey
                          </p>
                          <a href="timeline-page.html">
                            <h4 className="card-title text-sm mt-0.5 !text-white">
                              {" "}
                              John Michael{" "}
                            </h4>
                          </a>
                        </div>
                        {/* close */}
                        <button
                          type="button"
                          className="uk-transition-fade absolute top-0 right-0 m-2 z-10 bg-black/20 rounded-full flex p-1"
                        >
                          <ion-icon name="close" className="text-white" />
                        </button>
                      </div>
                    </li>
                    <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                      <div className="card uk-transition-toggle">
                        <a href="timeline-page.html">
                          <div className="card-media sm:aspect-[2/1.9] h-40">
                            <img
                              src="/static/assets/images/avatars/avatar-3.jpg"
                              alt=""
                            />
                            <div className="card-overly" />
                          </div>
                        </a>
                        <div className="card-body p-3 w-full z-10 absolute bg-gradient-to-t bottom-0 from-black/60">
                          <p className="card-text text-xs text-white/80">
                            {" "}
                            Dubai
                          </p>
                          <a href="timeline-page.html">
                            <h4 className="card-title text-sm mt-0.5 !text-white">
                              {" "}
                              Monroe Parker{" "}
                            </h4>
                          </a>
                        </div>
                        {/* close */}
                        <button
                          type="button"
                          className="uk-transition-fade absolute top-0 right-0 m-2 z-10 bg-black/20 rounded-full flex p-1"
                        >
                          <ion-icon name="close" className="text-white" />
                        </button>
                      </div>
                    </li>
                    <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                      <div className="card uk-transition-toggle">
                        <a href="timeline-page.html">
                          <div className="card-media sm:aspect-[2/1.9] h-40">
                            <img
                              src="/static/assets/images/avatars/avatar-4.jpg"
                              alt=""
                            />
                            <div className="card-overly" />
                          </div>
                        </a>
                        <div className="card-body p-3 w-full z-10 absolute bg-gradient-to-t bottom-0 from-black/60">
                          <p className="card-text text-xs text-white/80">
                            {" "}
                            London
                          </p>
                          <a href="timeline-page.html">
                            <h4 className="card-title text-sm mt-0.5 !text-white">
                              {" "}
                              Martin Gray{" "}
                            </h4>
                          </a>
                        </div>
                        {/* close */}
                        <button
                          type="button"
                          className="uk-transition-fade absolute top-0 right-0 m-2 z-10 bg-black/20 rounded-full flex p-1"
                        >
                          <ion-icon name="close" className="text-white" />
                        </button>
                      </div>
                    </li>
                    <li className="lg:w-1/4 sm:w-1/3 w-1/2">
                      <div className="card uk-transition-toggle">
                        <a href="timeline-page.html">
                          <div className="card-media sm:aspect-[2/1.9] h-40">
                            <img
                              src="/static/assets/images/avatars/avatar-6.jpg"
                              alt=""
                            />
                            <div className="card-overly" />
                          </div>
                        </a>
                        <div className="card-body p-3 w-full z-10 absolute bg-gradient-to-t bottom-0 from-black/60">
                          <p className="card-text text-xs text-white/80">
                            {" "}
                            country6
                          </p>
                          <a href="timeline-page.html">
                            <h4 className="card-title text-sm mt-0.5 !text-white">
                              {" "}
                              Alexa stella{" "}
                            </h4>
                          </a>
                        </div>
                        {/* close */}
                        <button
                          type="button"
                          className="uk-transition-fade absolute top-0 right-0 m-2 z-10 bg-black/20 rounded-full flex p-1"
                        >
                          <ion-icon name="close" className="text-white" />
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* slide nav icons */}
                <a className="nav-prev" href="#" uk-slider-item="previous">
                  {" "}
                  <ion-icon name="chevron-back" className="text-2xl" />{" "}
                </a>
                <a className="nav-next" href="#" uk-slider-item="next">
                  {" "}
                  <ion-icon name="chevron-forward" className="text-2xl" />
                </a>
              </div>
              <div id="page-tabs" className="uk-switcher mt-10">
                {/* pages card layout 1 */}
                <div
                  className="grid sm:grid-cols-3 grid-cols-2 gap-3"
                  uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100 ;repeat: true"
                >
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-3.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Monroe Parker </h4>{" "}
                      </a>
                      <p className="card-text">125k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-4.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title">Martin Gray </h4>{" "}
                      </a>
                      <p className="card-text"> 320k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-5.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> James Lewis </h4>{" "}
                      </a>
                      <p className="card-text">192k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-6.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title">Alexa stella </h4>{" "}
                      </a>
                      <p className="card-text">flow6</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-2.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> John Michael </h4>{" "}
                      </a>
                      <p className="card-text">260k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-3.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Monroe Parker </h4>{" "}
                      </a>
                      <p className="card-text">125k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-3.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Monroe Parker </h4>{" "}
                      </a>
                      <p className="card-text">320k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-4.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title">Martin Gray </h4>{" "}
                      </a>
                      <p className="card-text">320k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <a href="timeline-page.html">
                      <div className="card-media sm:aspect-[2/1.7] h-40">
                        <img src="/static/assets/images/avatars/avatar-5.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> James Lewis </h4>{" "}
                      </a>
                      <p className="card-text">192k Following</p>
                      <button
                        type="button"
                        className="button bg-primary text-white"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                  {/* load more */}
                  <div className="flex justify-center my-6 lg:col-span-3 col-span-2">
                    <button
                      type="button"
                      className="bg-white py-2 px-5 rounded-full shadow-md font-semibold text-sm dark:bg-dark2"
                    >
                      Load more...
                    </button>
                  </div>
                </div>
                {/* pages card layout 2*/}
                <div
                  className="grid sm:grid-cols-2 gap-3"
                  uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100 ;repeat: true"
                >
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-1.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Jesse Steeve </h4>{" "}
                      </a>
                      <p className="card-text"> 162k Following </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-2.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> John Michael </h4>{" "}
                      </a>
                      <p className="card-text"> 260k Following </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-3.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Monroe Parker </h4>{" "}
                      </a>
                      <p className="card-text"> 125k Following</p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-4.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Martin Gray</h4>{" "}
                      </a>
                      <p className="card-text"> 320k Following </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-5.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> James Lewis</h4>{" "}
                      </a>
                      <p className="card-text"> 320k Following5 </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-6.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Alexa stella</h4>{" "}
                      </a>
                      <p className="card-text"> flow6 </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-1.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> Jesse Steeve </h4>{" "}
                      </a>
                      <p className="card-text"> 162k Following </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card flex space-x-5 p-5">
                    <a href="timeline-page.html">
                      <div className="card-media w-16 h-16 shrink-0 rounded-full">
                        <img src="/static/assets/images/avatars/avatar-2.jpg" alt="" />
                        <div className="card-overly" />
                      </div>
                    </a>
                    <div className="card-body flex-1 p-0">
                      <a href="timeline-page.html">
                        {" "}
                        <h4 className="card-title"> John Michael </h4>{" "}
                      </a>
                      <p className="card-text"> 260k Following </p>
                      <div className="flex gap-1 mt-1">
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                          </svg>
                          Liked
                        </button>
                        <button
                          type="button"
                          className="button bg-primary-soft text-primary dark:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* load more */}
                  <div className="flex justify-center my-6 sm:col-span-2">
                    <button
                      type="button"
                      className="bg-white py-2 px-5 rounded-full shadow-md font-semibold text-sm dark:bg-dark2"
                    >
                      Load more...
                    </button>
                  </div>
                </div>
                {/* pages card layout 3 */}
                <div
                  className="grid sm:grid-cols-3 grid-cols-2 gap-3"
                  uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100 ;repeat: true"
                >
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-1.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-1.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> Jesse Steeve </h4>
                      <p className="card-text"> 125k Following1 </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-2.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-2.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> John Michael </h4>
                      <p className="card-text"> 260k Following </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-3.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-3.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> Monroe Parker </h4>
                      <p className="card-text"> 125k Following </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-4.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-4.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> Martin Gray </h4>
                      <p className="card-text"> 320k Following </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-5.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-5.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> James Lewis </h4>
                      <p className="card-text"> 192k Following </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-1.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-1.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> Jesse Steeve </h4>
                      <p className="card-text"> 125k Following1 </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-1.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-1.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> Jesse Steeve </h4>
                      <p className="card-text"> 125k Following1 </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-3.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-3.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> Monroe Parker </h4>
                      <p className="card-text"> 125k Following </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-media sm:h-24 h-16">
                      <img src="/static/assets/images/group/group-cover-2.jpg" alt="" />
                      <div className="card-overly" />
                    </div>
                    <div className="card-body relative z-10">
                      <img
                        src="/static/assets/images/avatars/avatar-2.jpg"
                        alt=""
                        className="w-10 rounded-full sm:mb-2 mb-1 shadow -mt-8 relative border-2 border-white"
                      />
                      <h4 className="card-title"> John Michael </h4>
                      <p className="card-text"> 260k Following </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className="button bg-primary text-white flex-1"
                        >
                          Join
                        </button>
                        <button
                          type="button"
                          className="button bg-secondery !w-auto"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* load more */}
                  <div className="flex justify-center my-6 lg:col-span-3 col-span-2">
                    <button
                      type="button"
                      className="bg-white py-2 px-5 rounded-full shadow-md font-semibold text-sm dark:bg-dark2"
                    >
                      Load more...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sidebar */}
          <div className="2xl:w-[380px] lg:w-[330px] w-full">
            <div
              className="lg:space-y-6 space-y-4 lg:pb-8 max-lg:grid sm:grid-cols-2 max-lg:gap-6"
              uk-sticky="media: 1024; end: #js-oversized; offset: 80"
            >
              <div className="box p-5 px-6">
                <div className="flex items-baseline justify-between text-black dark:text-white">
                  <h3 className="font-bold text-base"> Pages You Manage </h3>
                  <a href="#" className="text-sm text-blue-500">
                    See all
                  </a>
                </div>
                <div className="side-list">
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-2.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> John Michael</h4>
                      </a>
                      <div className="side-list-info"> Updated 2 day ago </div>
                    </div>
                    <button className="button bg-secondery">Edit</button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-4.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Martin Gray</h4>
                      </a>
                      <div className="side-list-info"> Updated 4 day ago </div>
                    </div>
                    <button className="button bg-secondery">Edit</button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-3.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Monroe Parker</h4>
                      </a>
                      <div className="side-list-info"> Updated 1 week ago </div>
                    </div>
                    <button className="button bg-secondery">Edit</button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-1.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Jesse Steeve</h4>
                      </a>
                      <div className="side-list-info">
                        {" "}
                        Updated 2 month ago{" "}
                      </div>
                    </div>
                    <button className="button bg-secondery">Edit</button>
                  </div>
                </div>
              </div>
              {/* Groups You Manage  */}
              <div className="bg-white rounded-xl shadow p-5 px-6 border1 dark:bg-dark2">
                <div className="flex items-baseline justify-between text-black dark:text-white">
                  <h3 className="font-bold text-base"> pages you manage </h3>
                  <a href="#" className="text-sm text-blue-500">
                    See all
                  </a>
                </div>
                <div className="side-list">
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-2.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> John Michael</h4>
                      </a>
                      <div className="side-list-info"> Updated 6 day ago </div>
                    </div>
                    <button className="button bg-primary-soft text-primary dark:text-white">
                      Like
                    </button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-4.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Martin Gray</h4>
                      </a>
                      <div className="side-list-info">
                        {" "}
                        Updated 2 month ago{" "}
                      </div>
                    </div>
                    <button className="button bg-primary-soft text-primary dark:text-white">
                      Like
                    </button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-3.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Monroe Parker</h4>
                      </a>
                      <div className="side-list-info"> Updated 1 week ago </div>
                    </div>
                    <button className="button bg-primary-soft text-primary dark:text-white">
                      Like
                    </button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-1.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Jesse Steeve</h4>
                      </a>
                      <div className="side-list-info"> Updated 2 day ago </div>
                    </div>
                    <button className="button bg-primary-soft text-primary dark:text-white">
                      Like
                    </button>
                  </div>
                </div>
                <button className="bg-secondery w-full text-black py-1.5 font-medium px-3.5 rounded-md text-sm mt-3 dark:text-white">
                  See all
                </button>
              </div>
              {/* Groups You Manage  */}
              <div className="bg-white rounded-xl shadow p-5 px-6 border1 dark:bg-dark2">
                <div className="flex items-baseline justify-between text-black dark:text-white">
                  <h3 className="font-bold text-base"> Suggested pages </h3>
                  <a href="#" className="text-sm text-blue-500">
                    See all
                  </a>
                </div>
                <div className="side-list">
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-2.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> John Michael</h4>
                      </a>
                      <div className="side-list-info"> Updated 1 week ago </div>
                    </div>
                    <button className="button bg-primary text-white">
                      Like
                    </button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-4.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Martin Gray</h4>
                      </a>
                      <div className="side-list-info"> Updated 4 week ago </div>
                    </div>
                    <button className="button bg-primary text-white">
                      Like
                    </button>
                  </div>
                  <div className="side-list-item">
                    <a href="timeline-group.html">
                      <img
                        src="/static/assets/images/avatars/avatar-3.jpg"
                        alt=""
                        className="side-list-image rounded-full"
                      />
                    </a>
                    <div className="flex-1">
                      <a href="timeline-group.html">
                        <h4 className="side-list-title"> Monroe Parker</h4>
                      </a>
                      <div className="side-list-info">
                        {" "}
                        Updated 2 month ago{" "}
                      </div>
                    </div>
                    <button className="button bg-primary text-white">
                      Like
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Follow;
