import "./App.css";
import Home from "./pages/Home";
import GroupPage from "./pages/GroupPage";
import MessagePage from "./pages/MessagePage";
import VideoPage from "./pages/VideoPage";
import EventsPage from "./pages/EventsPage";
import FollowPage from "./pages/FollowPages";
import MarketPage from "./pages/MarketPage";
import BlogPage from "./pages/BlogPage";
import SettingPage from "./pages/SettingPage";
import UpgradePage from "./pages/UpgradePage";
import TimelinePage from "./pages/TimelinePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/group" element={<GroupPage />} />
          <Route path="/message" element={<MessagePage />} />
          <Route path="/video" element={<VideoPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/follow" element={<FollowPage />} />
          <Route path="/market" element={<MarketPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
          <Route path="/timeline" element={<TimelinePage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
