import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ChatBox from "../components/ChatBox";
import CreateStatus from "../components/CreateStatus";
import CreateStory from "../components/CreateStory";
import PreviewModal from "../components/PreviewModal";
import Message from "../components/Message";

const MessagePage = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Message />
      <ChatBox />
      <CreateStatus />
      <CreateStory />
      <PreviewModal />
    </>
  );
};

export default MessagePage;
